<template>
    <div class="container-fluid">
        <Head></Head>
        <div class="row">
            <div class="col-lg-12" style="top: -220px;overflow: hidden;">
                <div class="container_contS">

                    <div class="container_contS_list">
                        <div class="container_contS_img">
                            <img alt=""
                                 src="../../../assets/image/professionserve/1.webp">
                        </div>
                        <div class="container_contS_title">
                            <h2 class="professionserve01">IT Asset Disposition</h2>
                        </div>
                        <div class="container_contS_btn">
                            <button @click="gotommore" class="professionserve02">More</button>
                        </div>
                    </div>


                    <div class="container_contS_list">
                        <div class="container_contS_img">
                            <img alt=""
                                 src="../../../assets/image/professionserve/2.webp">
                        </div>
                        <div class="container_contS_title">
                            <h2 class="professionserve01">Data Security and Destruction</h2>
                        </div>
                        <div class="container_contS_btn">
                            <button @click="gotommore" class="professionserve02">More</button>
                        </div>
                    </div>

                    <div class="container_contS_list">
                        <div class="container_contS_img">
                            <img alt=""
                                 src="../../../assets/image/professionserve/3.webp">
                        </div>
                        <div class="container_contS_title">
                            <h2 class="professionserve01">LOGISTIC MANAGEMENT</h2>
                        </div>
                        <div class="container_contS_btn">
                            <button @click="gotommore" class="professionserve02">More</button>
                        </div>
                    </div>


                </div>


            </div>
        </div>
        <Bottom style="margin-top: -250px;margin-left: -30px"></Bottom>
    </div>
</template>

<script>
    import {Bottom, Head} from "../commponents";

    export default {
        name: "ProfessionServe",
         metaInfo: {
        meta: [{                 
            name: '暹诺回收官网,手机回收,笔记本回收,数码产品回收,企业回收',
            content: '上海暹诺环保科技有限公司 (简称: 暹诺环保©)是中国领先的环保解决方案提供商，为国内500多家商业和工业企业客户提供回收服务。我们投资开发电子垃圾解决方案，用以适应不断变化的世界。目前该领域不仅包括处理和回收，还包括确保敏感数据的安全的方案，以帮助我们的客户实现零废物的绿色目标。作为中国领先的电子废弃物管理服务供应商，我们的服务宗旨是在最大限度降低影响的同时，又能最大限度地提高资源价值，为所有利益相关者促进经济和环境的可持续发展。'
        }]
        },
        components: {Bottom, Head},
        methods: {
            gotommore() {
                this.$router.push('/itciorecycle')
            }
        },
        created() {
            $(function () {
                $('.professionserve01').animate({
                    opacity: '1',

                }, 2000)


                $(window).scroll(function () {
                    var s = $(window).scrollTop();

                    if (s > 150) {
                        $('.professionserve02').animate({
                            opacity: '1',
                            top: '55px'
                        }, 1500)
                    }

                });


            })

        }
    }


    $(function () {
        $('.professionserve01').animate({
            opacity: '1',

        }, 2000)


        $(window).scroll(function () {
            var s = $(window).scrollTop();

            if (s > 150) {
                $('.professionserve02').animate({
                    opacity: '1',
                    top: '55px'
                }, 1500)
            }

        });


    })


</script>

<style scoped>
    .professionserve01 {
        opacity: 0;

    }

    .professionserve02 {
        opacity: 0;
        position: relative;
    }


    /*625 860*/
    .container_contS {
        height: 820px;

        margin-left: -15px;
        overflow: hidden;
        background-color: #f1f1f1;
    }

    .container_contS .container_contS_list {
        height: 860px;
        width: 33.3%;
        float: left;

    }

    .container_contS .container_contS_list .container_contS_img {
        height: 500px;
        width: 100%;

    }

    .container_contS_img img {
        height: 500px;
        width: 100%;
    }

    .container_contS_title {
        height: 100px;
        width: 100%;
        margin-top: 35px;
        text-align: center;
    }

    .container_contS_title h2 {
        line-height: 55px;
        color: black;
        font-weight: 800;
    }

    .container_contS_btn {
        height: 80px;
        width: 100%;
        text-align: center;
        margin-top: 25px;

    }

    .container_contS_btn button {
        background-color: black;
        color: white;
        height: 40px;
        width: 120px;
        margin-top: 15px;
        border-radius: 5px;
    }


</style>